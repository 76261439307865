<template>
  <div>
    <el-row class="row-bg">
      <el-space direction="vertical" alignment="left">
        <el-col :span="24">
          <span class="title">趣玩工具</span>
        </el-col>
      </el-space>
    </el-row>
    <br/>
    <el-space wrap>
      <el-card class="tool-card">
        <template #header>
          <div>
            <strong>头发依赖度计算</strong>
          </div>
        </template>
        <div>
          <el-space direction="vertical" alignment="left">
            <div>
              <span>你今天掉了<el-input-number v-model="hairNum" :min="0" :max="999999999" size="small" />根头发？</span>
            </div>
            <div>
              <el-button type="primary" style="width:100%" @click="hairBtn()">开始计算</el-button>
            </div>
            <div>
              <sapn>{{hairResult}}</sapn>
            </div>
          </el-space>
        </div>
      </el-card>
      <el-card class="tool-card">
        <template #header>
          <div>
            <strong>年龄计算器</strong>
          </div>
        </template>
        <div>
          <el-space direction="vertical" alignment="left">
            <div>
              <span>你已经过了<el-input-number v-model="birthdayNum" :min="0" :max="999999999" size="small" />个生日？</span>
            </div>
            <div>
              <el-button type="primary" style="width:100%" @click="ageBtn()">开始计算</el-button>
            </div>
            <div>
              <sapn>{{ageResult}}</sapn>
            </div>
          </el-space>
        </div>
      </el-card>
      <el-card class="tool-card">
        <template #header>
          <div>
            <strong>身体质量指数（BMI）</strong>
          </div>
        </template>
        <div>
          <el-space direction="vertical" alignment="left">
            <el-form :inline="true" :model="bmiParam" ref="bmiForm">
              <el-form-item size="small" label="身高：" prop="height" :rules="[
                  { required: true, message: '身高不能为空' },
                  { type: 'number', message: '请输入数字' },
                ]">
                <el-input v-model.number="bmiParam.height">
                  <template #suffix>
                    <span>cm</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item size="small" label="体重：" prop="weight" :rules="[
                  { required: true, message: '体重不能为空' },
                  { type: 'number', message: '请输入数字' },
                ]">
                <el-input v-model.number="bmiParam.weight">
                  <template #suffix>
                    <span>kg</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item size="small" label="姓名：" prop="name" :rules="[
                  { required: true, message: '姓名不能为空' },
                ]">
                <el-input v-model.number="bmiParam.name"/>
              </el-form-item>
              <el-form-item size="small">
                <el-button type="primary" style="width:100%" @click="bmiBtn()">开始计算</el-button>
              </el-form-item>
            </el-form>
            <div>
              <sapn>{{bmiResult}}</sapn>
            </div>
          </el-space>
        </div>
      </el-card>
      <el-card class="tool-card">
        <template #header>
          <div>
            <strong>快递体积重量</strong>
          </div>
        </template>
        <div>
          <el-space direction="vertical" alignment="left">
            <el-form :inline="true" :model="vWeight" ref="vWeightForm">
              <el-form-item label="抛比：" prop="bubble" :rules="[
                  { required: true, message: '抛比不能为空' },
                  { type: 'number', message: '请输入数字' },
                ]">
                <el-input v-model.number="vWeight.bubble" @input="vWeightBtn()"></el-input>
              </el-form-item>
              <el-form-item label="体积：" prop="volume" :rules="[
                  { required: true, message: '体积不能为空' },
                  { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,10})?$)|(^(0){1}$)|(^[0-9]\.[0-9]{1,10}?$)/, message: '请输入小数' },
                ]">
                <el-input v-model.text="vWeight.volume" @input="vWeightBtn()">
                  <template #suffix>
                    <span>m³</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
            <div>
              <sapn style="padding-left: 65px;">{{vWeightResult}}<span v-if="vWeightResult"> kg</span></sapn>
            </div>
          </el-space>
        </div>
      </el-card>
    </el-space>
  </div>
</template>

<script>

export default {
  name: "FunTools",
  components: {
  },
  data() {
    return {
      hairNum: 0,
      hairResult: "",
      birthdayNum: 0,
      ageResult: "",
      bmiParam: {
        height: null,
        weight: null,
        name: ""
      },
      bmiResult: "",
      vWeight: {
        bubble: 6000,
        volume: null
      },
      vWeightResult: ""
    };
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    hairBtn(){
      if(this.hairNum < 50){
        this.hairResult = "你的头发非常依恋你！";
      }else if(this.hairNum <= 100){
        this.hairResult = "你的头发想与你平平淡淡的度过一生！"
      }else if(this.hairNum <= 200){
        this.hairResult = "你和你的头发感情出现了危机！"
      }else if(this.hairNum <= 500){
        this.hairResult = "你的头发已决定离你而去！"
      }else{
        this.hairResult = "你将永远与你的头发告别！"
      }
    },
    ageBtn(){
      this.ageResult = "你已经"+this.birthdayNum+"岁了！";
    },
    bmiBtn(){
      this.$refs['bmiForm'].validate((valid) => {
        if(valid){
          let bmi = this.bmiParam.weight / Math.pow((this.bmiParam.height/100), 2);
          bmi = bmi.toFixed(2)
          if(this.bmiParam.name == "刘星辰"){
            this.bmiResult = "你的身材真棒！";
            return true;
          }
          this.bmiResult = "";
          // this.bmiResult = "你的BMI为：" + bmi + "\n";
          if(bmi < 18){
            this.bmiResult = this.bmiResult + "一阵风都能把你吹跑了！"
          }else if(bmi < 20){
            this.bmiResult = this.bmiResult + "你该多吃点饭了！"
          }else if(bmi < 25){
            this.bmiResult = this.bmiResult + "你的身材真棒！"
          }else if(bmi < 30){
            this.bmiResult = this.bmiResult + "你有点发福了！"
          }else{
            this.bmiResult = this.bmiResult + "你已经放飞自我了！"
          }
        }else{
          this.bmiResult = "";
          return false;
        }
      })
    },
    vWeightBtn(){
      this.$refs['vWeightForm'].validate((valid) => {
        if(valid){
          if(this.vWeight.bubble > 0){
            let result = this.vWeight.volume * 1000000 / this.vWeight.bubble;
            this.vWeightResult = result.toFixed(2)
          }else{
            this.vWeightResult = "";
          }
        }else{
          this.vWeightResult = "";
          return false;
        }
      })
    }
  }
}
</script>

<style scoped>
.tool-card{
  width: 330px;
  height: 300px;
}
</style>