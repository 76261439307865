<template>
    <el-menu :default-active="$route.path" router :collapse="isCollapse">
        <el-button @click="fold()" style="width:100%">
            <el-icon :class="isCollapse ? 'icon-rotate' : ''"><Fold /></el-icon>
        </el-button>
        <el-menu-item index="/plan">
            <el-icon><DocumentChecked/></el-icon>
            <template #title>计划进度</template>
        </el-menu-item>
        <el-menu-item index="/name">
            <el-icon><User/></el-icon>
            <template #title>随机姓名</template>
        </el-menu-item>
        <el-menu-item index="/fun">
            <el-icon><Tools/></el-icon>
            <template #title>趣玩工具</template>
        </el-menu-item>
    </el-menu>
</template>

<script>
import { DocumentChecked, User, Fold, Tools } from "@element-plus/icons-vue";
export default {
    name: "Aside",
    components: {
        DocumentChecked,
        User,
        Fold,
        Tools,
    },
    data() {
        return {
            isCollapse: false,
        }
    },
    methods:{
        fold(){
            this.isCollapse = !this.isCollapse
        }
    }
}
</script>

<style scoped>
.el-menu{
    height: 100%;
}
.icon-rotate{
    transform: rotate(180deg);
}
</style>