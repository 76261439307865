<template>
  <div id="plan-list">
    <el-row class="row-bg">
      <el-col :sm="12" :xs="24">
        <el-row>
          <el-space direction="vertical" alignment="left">
            <el-col :span="24">
              <span class="title">计划进度</span>
            </el-col>
            <el-col :span="24">
              <el-space wrap>
                <el-button type="primary" @click="add">新增</el-button>
                <el-button type="primary" @click="dialogVisibleLocal = true">本地备份</el-button>
                <el-button type="primary" @click="dialogVisibleCloud = true">云备份</el-button>
                <el-button type="primary" @click="exportExcel">导出Excel</el-button>
              </el-space>
            </el-col>
            <el-col :span="24">
              <el-space wrap>
                <el-input
                  v-model="query.search"
                  placeholder="搜索"
                >
                  <template #prefix>
                    <el-icon>
                      <Search />
                    </el-icon>
                  </template>
                </el-input>
                <el-select
                  v-model="query.steps"
                  placeholder="进度"
                  multiple
                  clearable
                >
                  <el-option
                    v-for="item in steps"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
                <el-button @click="resertQuery">重置</el-button>
              </el-space>
            </el-col>
          </el-space>
        </el-row>
        
      </el-col>
      <el-col :sm="12" :xs="24" align="right">
        <div id="echarts_pie_step" class="echarts_pie_step_class"></div>
      </el-col>
    </el-row>
    <br />
    <el-table
      id="planTable"
      :data="filterTableData()"
      row-key="id"
      border
      style="width: 100%"
      :row-class-name="rowClassName"
      @row-dblclick="editClick"
      ref="planTable"
    >
      <el-table-column
        prop="id"
        label="编号"
        sortable
        width="80"
        align="center"
      />
      <!-- <el-table-column
        prop="sort"
        label="排序"
        sortable
        width="80"
        align="center"
      /> -->
      <el-table-column
        prop="createTime"
        label="创建日期"
        sortable
        width="105"
        :formatter="formatDate"
      />
      <el-table-column prop="name" label="描述">
        <template #default="scope">
          <el-input
            v-model="scope.row.name"
            autosize
            type="textarea"
            v-if="scope.row.flag != 0"
          ></el-input>
          <span v-if="scope.row.flag == 0">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="step"
        sortable
        label="进度"
        :width="haveEdit() ? 120 : 80"
      >
        <template #default="scope">
          <el-select
            v-model="scope.row.step"
            v-if="scope.row.flag != 0"
            placeholder="进度"
          >
            <el-option
              v-for="item in steps"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            />
          </el-select>
          <span v-if="scope.row.flag == 0">{{ scope.row.step }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" type="textarea" label="备注">
        <template #default="scope">
          <el-input
            v-model="scope.row.remark"
            autosize
            type="textarea"
            v-if="scope.row.flag != 0"
          ></el-input>
          <span v-if="scope.row.flag == 0">{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="overTime"
        label="完成日期"
        :width="haveEdit() ? 245 : 105"
      >
        <template #default="scope">
          <el-date-picker
            v-model="scope.row.overTime"
            v-if="scope.row.flag != 0"
            type="date"
            :disabled-date="disabledOverTime(scope.row).disabledDate"
          />
          <span v-if="scope.row.flag == 0" :class="overTimeTextColor(scope.row)">{{
            formatDate(null, null, scope.row.overTime, null)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="110">
        <template #default="scope">
          <el-button
            type="primary"
            v-if="scope.row.flag == 0"
            @click="editClick(scope.row)"
            circle
          >
            <el-icon>
              <Edit />
            </el-icon>
          </el-button>
          <el-popconfirm title="确定删除吗?" @confirm="deleteRow(scope.row.id)">
            <template #reference>
              <el-button type="danger" v-if="scope.row.flag == 0" circle>
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-popconfirm>
          <el-button
            type="success"
            v-if="scope.row.flag != 0"
            @click="saveEdit(scope.row.id)"
            circle
          >
            <el-icon>
              <Check />
            </el-icon>
          </el-button>
          <el-button
            type="warning"
            v-if="scope.row.flag != 0"
            @click="cancelEdit(scope.row.id)"
            circle
          >
            <el-icon>
              <Close />
            </el-icon>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="dialogVisible"
      title="警告"
      width="30%"
      @close="closeDialog"
    >
      <span>恢复备份将清空当前数据，是否继续？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="recoverBak">确定</el-button>
        </span>
      </template>
    </el-dialog>
                
    <el-dialog
      v-model="dialogVisibleLocal"
      title="本地备份"
      width="20%"
    >
      <el-space wrap>
        <el-button type="primary" @click="downloadPlanList">备份</el-button>
        <el-upload
          :on-change="readPlanList"
          :show-file-list="false"
          :auto-upload="false"
          accept=".planbak"
        >
          <el-button type="primary">恢复备份</el-button>
        </el-upload>
      </el-space>
    </el-dialog>
    <el-dialog
      v-model="dialogVisibleCloud"
      title="云备份"
      width="30%"
    >
      <el-form :model="cloudBakParam" label-width="80px" :rules="cloudBakRules" ref="cloudBakParam">
        <el-form-item prop="email" label="邮箱：" :error="email.error" :validate-status="email.status">
          <el-input type="email" v-model="cloudBakParam.email" />
        </el-form-item>
        <el-form-item prop="code" label="验证码：">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input v-model="cloudBakParam.code" />
            </el-col>
            <el-col :span="12">
              <el-button @click="sendCode" style="margin-top:-4px" :disabled="sendButton">{{sendButton ? sendButtonVal : "发送"}}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="cloudBak('cloudBakParam')">备份</el-button>
          <el-button type="primary" @click="cloudReceive('cloudBakParam')">恢复备份</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Delete, Edit, Check, Search, Close } from "@element-plus/icons-vue";
import { saveAs } from "file-saver";
import { ElMessage } from "element-plus";
import * as XLSX from "xlsx";
import Sortable from 'sortablejs';
export default {
  name: "PlanList",
  components: {
    Delete,
    Edit,
    Check,
    Search,
    Close
  },
  data() {
    return {
      tableData: [
        // {
        //   sort: 1,
        //   id: 1,
        //   createTime: new Date(),
        //   name: "",
        //   step: "",
        //   remark: "",
        //   overTime: null,
        //   flag: 2,  //0 正常数据，1 正在编辑的数据，2 新增还未保存的数据
        // }
      ],
      editTableData: [], //编辑备份数据，用于撤销编辑时恢复数据
      echartsPieStep: null,
      steps: [
        { text: "待反馈", value: "待反馈" },
        { text: "进行中", value: "进行中" },
        { text: "测试中", value: "测试中" },
        { text: "已完成", value: "已完成" },
        { text: "推迟", value: "推迟" },
      ],
      stepOptionData: [],
      dialogVisible: false,
      dialogVisibleCloud: false,
      dialogVisibleLocal: false,
      tableDataBak: null,
      query: null,
      cloudBakParam: {
        email: "",
        code: "",
        planData: ""
      },
      cloudBakRules: {
        email:[
          {required: true, message: '请输入邮箱', trigger: 'blur'}
        ],
        code:[
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      },
      email: {
        error: "",
        status: ""
      },
      sendButton: false,
      sendButtonVal: "",
      sendTimer: null,
      sendSecond: 60,
      sortable: null,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.drawPie();
    this.initSortable();
  },
  methods: {
    initSortable(){
      const el = this.$refs.planTable.$el.querySelectorAll('tbody')[0];
      this.sortable = Sortable.create(el, {
        animation: 150,
        ghostClass: 'sortable-ghost',
        // onUpdate: evt => {
        //   console.log(evt.oldIndex, evt.newIndex);
        //   let lineDoms = evt.to.rows;
        //   for(let i = 0; i < lineDoms.length; i++){
        //     let targetId = lineDoms[i].querySelector('.el-table_1_column_1 div').innerHTML.replace("<!---->", "").trim();
        //     this.getDataById(targetId).sort = i+1;
        //   }
        // },
        onEnd: evt => {
          console.log(evt.oldIndex, evt.newIndex);
          let lineDoms = evt.to.rows;
          for(let i = 0; i < lineDoms.length; i++){
            let targetId = lineDoms[i].querySelector('.el-table_1_column_1 div').innerHTML.replace("<!---->", "").trim();
            this.getDataById(targetId).sort = i+1;
          }
          this.saveTableData();
        }
      });
    },
    getDataById(id){
      return this.tableData.filter(item => item.id == id)[0];
    },
    //根据查询条件筛选表格数据
    filterTableData() {
      return this.tableData.filter(item => {
        //判断数组中是否包含某个值的方法
        const arrContains = (arr, val) => {
          console.log(arr)
          for(let a in arr){
            if(arr[a] === val){
              return true;
            }
          }
          return false;
        };
        //搜索逻辑：
        //1、描述或备注中是否包含关键字
        //2、如果进度条件不为空，按进度筛选数据
        //3、展示正在编辑中的数据
        return (item.name.toLowerCase().indexOf(this.query.search.toLowerCase()) != -1 
          || item.remark.toLowerCase().indexOf(this.query.search.toLowerCase()) != -1)
          && (this.query.steps.length === 0 || arrContains(this.query.steps, item.step))
          || item.flag != 0;
      }).sort((a, b) => {
        let r = a.sort-b.sort;
        if (r == 0){
          r = new Date(b.createTime).valueOf()-new Date(a.createTime).valueOf();
        }
        return r;
      });
    },
    //初始化数据
    initData() {
      this.resertQuery();
      this.tableData = JSON.parse(localStorage.getItem("tableData"));
      if (!this.tableData) {
        this.tableData = [];
      }
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].flag = 0;
        if(!this.tableData[i].sort){
          this.tableData[i].sort = this.tableData[i].id;
        }
      }
      this.stepOptionData = this.transferPieStepData();
    },
    //重置查询条件
    resertQuery() {
      this.query = {
        search: "",
        steps: [],
      }
    },
    //保存表格数据
    saveTableData() {
      localStorage.setItem("tableData", JSON.stringify(this.tableData));
      this.stepOptionData = this.transferPieStepData();
      this.drawPie();
    },
    //设置一行数据为编辑状态
    editClick(row) {
      this.editTableData.push(JSON.parse(JSON.stringify(row)));
      row.flag = 1;
    },
    //保存修改的数据
    saveEdit(id) {
      let row = this.getDataById(id);
      for(let i = 0; i < this.editTableData.length; i++){
        //删除编辑备份的数据
        if(this.editTableData[i].id == row.id){
          this.editTableData.splice(i, 1);
        }
      }
      row.flag = 0;
      this.saveTableData();
    },
    //取消编辑
    cancelEdit(id) {
      let row = this.getDataById(id);
      let index = this.tableData.indexOf(row);
      if(row.flag == 2){
        //如果是新增的数据，直接删除
        this.tableData.splice(index, 1);
      }else if(row.flag == 1){
        //如果是编辑的数据，恢复编辑前的数据
        for(let i = 0; i < this.editTableData.length; i++){
          if(this.editTableData[i].id == row.id){
            //恢复编辑前的数据
            this.tableData[index] = JSON.parse(JSON.stringify(this.editTableData[i]))
            //删除编辑备份的数据
            this.editTableData.splice(i, 1);
          }
        }
      }
    },
    //删除一条数据
    deleteRow(id) {
      let row = this.getDataById(id);
      let index = this.tableData.indexOf(row);
      this.tableData.splice(index, 1);
      this.saveTableData();
    },
    //根据进度和日期设置完成日期的颜色
    overTimeTextColor(row) {
      if (
        row.overTime &&
        new Date(row.overTime) <
          this.moment(new Date()).startOf("day").toDate() &&
        row.step !== "已完成"
      ) {
        //超过了完成日期还未完成
        return "danger-col";
      }
    },
    //根据进度设置背景颜色
    rowClassName(row) {
      if (row.row.step == "推迟") {
        return "danger-row";
      }
      if (row.row.step == "待反馈") {
        return "info-row";
      }
      if (row.row.step == "进行中") {
        return "primary-row";
      }
      if (row.row.step == "测试中") {
        return "warning-row";
      }
      if (row.row.step == "已完成") {
        return "success-row";
      }
      return "";
    },
    //格式化日期展示
    formatDate(row, column, cellValue, index) {
      if (!cellValue) {
        return null;
      }
      return this.moment(cellValue).format("YYYY-MM-DD");
    },
    //是否有编辑状态的数据
    haveEdit(){
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].flag != 0) {
          return true;
        }
      }
      return false;
    },
    //限制完成日期不能早于创建日期
    disabledOverTime(row) {
      var cDate = this.moment(row.createTime).startOf("day").toDate();
      return {
        disabledDate(time) {
          return time < cDate;
        },
      };
    },
    //在表格底部增加一条数据
    add() {
      var maxId = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id > maxId) {
          maxId = this.tableData[i].id;
        }
      }
      this.tableData.push({
        sort: -1,
        id: maxId + 1,
        createTime: new Date(),
        name: "",
        step: "",
        remark: "",
        overTime: null,
        flag: 2,
      });
    },
    //画进度统计饼图
    drawPie() {
      if (!this.echartsPieStep) {
        this.echartsPieStep = this.echarts.init(
          document.getElementById("echarts_pie_step")
        );
      }
      //解决上线后切换页面统计图不显示的问题
      document
        .getElementById("echarts_pie_step")
        .removeAttribute("_echarts_instance_");
      var stepOption = {
        series: [
          {
            type: "pie",
            data: this.stepOptionData,
          },
        ],
        radius: "50%",
      };
      this.echartsPieStep.setOption(stepOption);
    },
    //根据表格数据转换进度饼图数据
    transferPieStepData() {
      var stepOptionData = [];
      for (let i = 0; i < this.tableData.length; i++) {
        var name = this.tableData[i].step;
        var exist = false;
        for (let j = 0; j < stepOptionData.length; j++) {
          if (stepOptionData[j].name == name) {
            stepOptionData[j].value = stepOptionData[j].value + 1;
            exist = true;
            break;
          }
        }
        if (!exist) {
          stepOptionData.push({
            name: name,
            value: 1,
          });
        }
      }
      return stepOptionData;
    },
    //备份数据并下载
    downloadPlanList() {
      var timeStr = this.moment(new Date()).format("YYYYMMDDhhmmss");
      let strData = new Blob([JSON.stringify(this.tableData)], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(strData, "planList-" + timeStr + ".planbak");
    },
    //读取备份数据
    readPlanList(file) {
      if (typeof FileReader === "undefined") {
        alert("你的浏览器不支持");
        return;
      }
      var temp = null;
      var promise = new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.readAsText(file.raw, "UTF-8");
        reader.onload = () => {
          if (reader.result) {
            temp = reader.result;
            resolve();
          }
        };
      });
      promise.then(() => {
        this.tableDataBak = temp;
        this.dialogVisible = true;
      });
    },
    //恢复备份数据
    recoverBak() {
      if (this.tableDataBak) {
        try {
          this.tableData = JSON.parse(this.tableDataBak);
          this.saveTableData();
          ElMessage({
            message: "恢复备份数据成功！",
            type: "success",
          });
        } catch (e) {
          ElMessage({
            message: "恢复失败，备份数据已损坏！",
            type: "error",
          });
        }
      } else {
        ElMessage({
          message: "恢复失败，备份数据为空！",
          type: "warning",
        });
      }
      this.dialogVisible = false;
    },
    //关闭提示框的时候清空读取的备份数据
    closeDialog() {
      this.tableDataBak = null;
    },
    //导出Excel
    exportExcel() {
      var xlsxParam = { raw: true };
      var tableDom = document.querySelector("#planTable").cloneNode(true);
      //获取并删除操作列的所有元素
      var allOpTh = tableDom.querySelectorAll(".el-table-fixed-column--right");
      for (let i = 0; i < allOpTh.length; i++) {
        allOpTh[i].remove();
      }
      var wb = XLSX.utils.table_to_book(tableDom, xlsxParam);
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      var timeStr = this.moment(new Date()).format("YYYYMMDDhhmmss");
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        "planList-" + timeStr + ".xlsx"
      );
    },
    sendCode(){
      if(!this.cloudBakParam.email){
        this.email.error="请输入邮箱";
        return false;
      }else{
        this.email.error="";
      }
      this.axios.post('plan/send', this.cloudBakParam)
        .then(response => {
          if(response.data.code == "000000"){
            //验证码发送成功
            ElMessage({
              message: "验证码发送成功！",
              type: "success",
            });
            this.sendButton = true;
            this.count = 60;
            this.sendButtonVal = "重新发送 ("+this.count+"s)"
            if(!this.sendTimer){
              this.sendTimer = setInterval(() => {
                if(this.count > 1){
                  this.count--;
                  this.sendButtonVal = "重新发送 ("+this.count+"s)"
                }else{
                  this.sendButton = false;
                  clearInterval(this.sendTimer);
                  this.sendTimer = null;
                }
              }, 1000)
            }
          }else{
            ElMessage({
              message: "验证码发送失败："+response.data.msg,
              type: "error",
            });
          }
        });
    },
    cloudBak(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          this.cloudBakParam.planData = JSON.stringify(this.tableData);
          this.axios.post('plan/save', this.cloudBakParam)
            .then(response => {
              if(response.data.code == "000000"){
                //备份成功
                ElMessage({
                  message: "备份成功！",
                  type: "success",
                });
                this.cloudBakParam.planData = "";
              }else{
                ElMessage({
                  message: "备份失败："+response.data.msg,
                  type: "error",
                });
              }
            });
        }else{
          return false;
        }
      })
    },
    cloudReceive(formName){
      this.$refs[formName].validate((valid) => {
        if(valid){
          this.axios.post('plan/get', this.cloudBakParam)
            .then(response => {
              if(response.data.code == "000000"){
                //读取成功
                this.tableDataBak = response.data.data
                this.dialogVisible = true;
              }else{
                ElMessage({
                  message: "获取备份数据失败："+response.data.msg,
                  type: "error",
                });
              }
            });
        }else{
          return false;
        }
      })
    },
  },
};
</script>

<style>
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .danger-row {
  --el-table-tr-bg-color: var(--el-color-danger-light-9);
}
.el-table .primary-row {
  --el-table-tr-bg-color: var(--el-color-primary-light-9);
}
.el-table .info-row {
  --el-table-tr-bg-color: var(--el-color-info-light-9);
}
.el-table .danger-col {
  color: var(--el-color-danger);
}
.echarts_pie_step_class {
  width: 250px;
  height: 122px;
}
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #b0c4de !important;
}
</style>
