<template>
  <div id="app">
    <el-container>
      <el-aside width="auto"><Aside></Aside></el-aside>
      <el-main>
        <router-view v-slot="{ Component }">
          <transition mode="out-in"
          :enter-active-class="enterClass"
          :leave-active-class="leaveClass">
            <component class="animate__animated" :is="Component" />
          </transition>
        </router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Aside from './components/Aside.vue'

export default {
  name: 'App',
  components: {
    Aside
  },
  data() {
    return {
      enterClass: '',
      leaveClass: ''
    };
  },
  watch: {
    $route(to, from){
      if(to.meta.index >= from.meta.index){
        this.leaveClass='animate__slideOutUp'
        this.enterClass='animate__slideInUp'
      }else{
        this.leaveClass='animate__slideOutDown'
        this.enterClass='animate__slideInDown'
      }
    }
  },
}
</script>

<style>
.title {
  font-weight: bold;
  font-size: 26px;
  display: block;
}
</style>
