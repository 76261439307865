import { createRouter, createWebHashHistory } from 'vue-router'
import PlanList from '../components/PlanList.vue'
import RandomNameInfo from '../components/RandomNameInfo.vue'
import FunTools from '../components/FunTools.vue'

const routes = [
  {
    path: '/',
    redirect: '/plan'
  },
  {
    path: '/plan',
    name: 'PlanList',
    component: PlanList,
    meta: {
      index: 0
    }
  },
  {
    path: '/name',
    name: 'RandomNameInfo',
    component: RandomNameInfo,
    meta: {
      index: 1
    }
  },
  {
    path: '/fun',
    name: 'FunTools',
    component: FunTools,
    meta: {
      index: 2
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
