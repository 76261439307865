<template>
  <div>
    <el-row class="row-bg">
      <el-space direction="vertical" alignment="left">
        <el-col :span="24">
          <span class="title">随机姓名</span>
        </el-col>
        <el-col :span="24">
          <el-form :inline="true">
            <el-form-item label="姓氏:">
              <el-select
                v-model="fname"
                clearable
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                style="width:80px"
                placeholder=" "
              >
                <el-option
                  v-for="item in fnameList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="性别:">
              <el-select v-model="sex" 
                clearable
                placeholder=" " 
                style="width:80px">
                <el-option label="男" value="男" />
                <el-option label="女" value="女" />
              </el-select>
            </el-form-item>
            <el-form-item label="生成数量:">
              <el-input-number v-model="num" :min="1" :max="100" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="generate">生成</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-space>
    </el-row>
    <el-table :data="tableData" style="width: 100%" ref="nameTable">
      <!-- <el-table-column prop="index" label="序号" width="100px">
        <template #default="scope">
          <div v-html="tableData.indexOf(scope.row)"></div>
        </template>
      </el-table-column> -->
      <el-table-column prop="name" label="姓名" width="100px" />
      <el-table-column prop="sex" label="性别" width="56px" />
      <el-table-column prop="remark" label="说文解字">
        <template #default="scope">
          <div v-html="scope.row.remark"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
export default {
  name: "RandomNameInfo",
  components: {
  },
  data() {
    return {
      tableData: null,
      sex: "",
      fname: "",
      num: 10,
      fnameList: [],
      sortable: null,
    };
  },
  created() {
    this.initFnameList();
    this.generate();
  },
  mounted() {
    this.initSortable();
  },
  methods: {
    initSortable(){
      const el = this.$refs.nameTable.$el.querySelectorAll('tbody')[0];
      this.sortable = Sortable.create(el, {
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: evt => {
          console.log(evt.oldIndex, evt.newIndex);
        }
      });
    },
    initFnameList(){
      this.axios.get('randomname/fnameList')
        .then(response => {
          this.fnameList = response.data.data
        });
    },
    generate() {
      this.axios.get('randomname/generate',{
        params:{
          sex: this.sex,
          fname: this.fname,
          num: this.num
        }
      })
        .then(response => {
          this.tableData = response.data.data
          console.log(this.tableData)
        });
    }
  }
}
</script>

<style scoped>
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #b0c4de !important;
}
</style>