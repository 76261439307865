import axios from './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import moment from 'moment'
import * as echarts from 'echarts'
import router from './router'
import animate from 'animate.css'

const app = createApp(App).use(animate).use(router)

app.use(ElementPlus, {
    locale: zhCn,
  })
app.config.globalProperties.axios = axios
app.config.globalProperties.moment = moment
app.config.globalProperties.echarts = echarts
app.mount('#app')
